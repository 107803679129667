<template>
  <section>
    <progress-bouncing center v-if="isLoading"/>
    <div v-else>
      <h2 class="pt-3 pb-3">New import</h2>
      <div v-if="basicImport">
        <p>There is another import ongoing, please proceed to next step.</p>
      </div>
      <div v-else class="new-import">
        <p>In order to import products into Fixit's Product Catalogue, first select either a <strong>.xls</strong> or
          a <strong>.csv</strong> file.</p>
        <v-form ref="form" v-model="formValid">
          <v-file-input v-model="importFile" class="fileInput"
                        :rules="[!!importFile || 'Required']"
                        truncate-length="15"
                        show-size
                        label="Select a .xls or .csv file"
          ></v-file-input>
          <v-select :rules="[!!importFile || 'Required']"
                    placeholder="Date format"
                    prepend-icon="mdi-calendar"
                    :items="dateFormatOptions"
                    v-model="dateFormat"/>
          <v-checkbox
              class="mt-0"
              v-model="includeFileHeader"
              label="Include file headers (Select this if the first row contains the column titles) "
              hide-details=true
          />
          <v-checkbox
              class="mt-0"
              v-model="dontUpdateProducts"
              label="Don't update products"
              hide-details=true
          />
          <v-checkbox
              disabled
              class="mt-0"
              v-model="excludeNoBarcodeProducts"
              label="Exclude products missing barcodes"
              hide-details=true
          />
          <v-checkbox
              disabled
              class="mt-0"
              v-model="excludeNoSalesPriceProducts"
              label="Exclude products missing sales price"
              hide-details=true
          />
        </v-form>
      </div>
    </div>
  </section>
</template>

<script>
import wizardMixin from './wizard.mixin'

export default {
  name: "NewImport",
  mixins: [wizardMixin],
  props: {
    isLoading: Boolean
  },
  data() {
    return {
      formValid: false,
      importFile: null,
      dateFormatOptions: ['YYYY-MM-DD', 'DD-MM-YYYY', 'MM-DD-YYYY', 'DD.MM.YYYY'],
      dateFormat: '',
      includeFileHeader: false,
      dontUpdateProducts: false,
      excludeNoBarcodeProducts: false,
      excludeNoSalesPriceProducts: false
    }
  },
  methods: {
    async formSubmit() {
      if (this.basicImport)
        return true
      await this.$refs.form.validate()
      if (!this.formValid) return

      let formData = new FormData();
      formData.append("file", this.importFile, this.importFile.name);
      let basicImport = await this.$store.dispatch("basic-import/createNewImport", {
        fileType: 1, importOptions: {
          includeHeaders: this.includeFileHeader,
          excludeExistingProducts: this.dontUpdateProducts,
          excludeProductsMissingBarcodes: this.excludeNoBarcodeProducts,
          excludeProductsMissingSalesPrice: this.excludeNoSalesPriceProducts,
          dateFormat: this.dateFormat
        }
      });
      if (basicImport?.id) {
        await this.$store.dispatch("basic-import/uploadImportFile", {
          importId: basicImport.id,
          file: formData
        })
      }
      return true
    }
  },
  created() {
    this.getActiveImport()
  }
}
</script>

<style lang="scss" scoped>
.new-import {
  p {
    font-size: 20px;
    font-weight: 300;

    > strong {
      font-weight: bold;
    }
  }

  > *:not(p) {
    max-width: 220px;
    margin: 0 auto;
  }
}
</style>
